import React, { FunctionComponent } from "react";

import classNames from "classnames";

import Icon from "../Icon";
import Flex from "../Flex";
import Button from "../Button";
import Spacer from "../Spacer";

import styles from "./styles.module.scss";

interface CookieBannerProps {
  message: string;
  isOpen?: boolean;
  onClose?: () => void;
  onAccept?: () => void;
}

const CookieBanner: FunctionComponent<CookieBannerProps> = ({
  isOpen,
  message,
  onClose,
  onAccept,
}) => (
  <div
    className={classNames(styles.cookieBanner, {
      [styles.cookieBannerClosed]: !isOpen,
    })}
  >
    <div className={styles.cookieBannerWrapper}>
      <Flex flexDirection="row" alignItems="start">
        <Icon name="cookie" className={styles.cookieBannerMessageIcon} />
        <span className={styles.cookieBannerMessage}>
          {message} <a href="#">Mais Informações</a>
        </span>
      </Flex>
      <Flex
        flexDirection="row"
        alignItems="center"
        className={styles.cookieBannerButton}
      >
        <Button small secondary onClick={onAccept}>
          Aceitar
        </Button>
        <Spacer size={2} direction="horizontal" />
        <Icon
          name="crossSmall"
          onClick={onClose}
          className={styles.cookieBannerButtonIcon}
        />
      </Flex>
    </div>
  </div>
);

CookieBanner.defaultProps = {
  isOpen: false,
};

export default CookieBanner;
