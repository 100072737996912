import React, { FunctionComponent, InputHTMLAttributes } from "react";

import classNames from "classnames";

import Icon from "../Icon";
import Flex from "../Flex";
import Button from "../Button";

import styles from "./styles.module.scss";

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  message?: string;
  error?: boolean;
  success?: boolean;
  onClickArrowButton?: () => void;
}

const TextInput: FunctionComponent<TextInputProps> = ({
  id,
  label,
  message,
  error,
  success,
  required,
  className,
  onClickArrowButton,
  ...rest
}) => {
  const inputID = id || `${label}-${Math.random().toString(36).substring(7)}`;
  return (
    <div className={styles.inputBlock}>
      {label && (
        <label htmlFor={inputID} className={styles.inputLabel}>{`${label}${
          required ? " *" : ""
        }`}</label>
      )}
      <Flex flexDirection="row">
        <div className={styles.inputWrapper}>
          <input
            type="text"
            required={required}
            id={label && inputID}
            className={classNames(
              styles.input,
              {
                [styles.inputError]: error,
                [styles.inputSuccess]: success,
                [styles.inputHasIcon]: error || success,
                [styles.inputHasButton]: onClickArrowButton !== undefined,
              },
              className
            )}
            {...rest}
          />
          {error && (
            <Icon
              name="alert"
              className={classNames(styles.inputIcon, styles.inputIconError)}
            />
          )}
          {success && (
            <Icon
              name="checkmarkSmall"
              className={classNames(styles.inputIcon, styles.inputIconSuccess)}
            />
          )}
        </div>
        {onClickArrowButton && (
          <Button primary minWidth={false} className={styles.companionButton}>
            <Icon name="arrowRight" />
          </Button>
        )}
      </Flex>
      {message && (
        <span
          className={classNames(styles.inputMessage, {
            [styles.inputMessageError]: error,
          })}
        >
          {message}
        </span>
      )}
    </div>
  );
};

TextInput.defaultProps = {
  error: false,
  success: false,
};

export default TextInput;
