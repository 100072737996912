/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, Fragment, FunctionComponent } from "react";

import Text from "../components/Text";
import Flex from "../components/Flex";
import Button from "../components/Button";
import Layout from "../components/Layout";
import Spacer from "../components/Spacer";
import TextInput from "../components/TextInput";
import Container from "../components/Container";
import Icon, { Icons } from "../components/Icon";
import CookieBanner from "../components/CookieBanner";

import styles from "../styles/pages/ui-kit.module.scss";

const fontSizes = {
  xs: "Extra Small",
  sm: "Small",
  rg: "Regular",
  md: "Medium",
  bg: "Big",
  lg: "Large",
  xl: "Extra Large",
  xxl: "Extra-Extra Large",
};
const fontWeights = {
  xl: "Extra Light",
  lg: "Light",
  rg: "Normal",
  sb: "Semi-Bold",
  bl: "Bold",
  bk: "Black",
};
const icons: Icons[] = [
  "alert",
  "arrowRight",
  "bookmark",
  "bookmarkFilled",
  "bucket",
  "bucketFilled",
  "checkmark",
  "checkmarkSmall",
  "cookie",
  "cross",
  "crossSmall",
  "edit",
  "editFilled",
  "email",
  "facebook",
  "google",
  "heart",
  "heartFilled",
  "info",
  "infoFilled",
  "instagram",
  "loading",
  "menu",
  "messenger",
  "plus",
  "plusSmall",
  "search",
  "star",
  "starFilled",
  "twitter",
  "whatsapp",
  "youtube",
];

const UIKitPage: FunctionComponent = () => {
  const [showCookieBanner, setShowCookieBanner] = useState<boolean>(true);

  const renderTypography = (): JSX.Element => (
    <div id="typography">
      <Text h3 size="bg">
        Typography
      </Text>
      <Spacer size={1} />
      <Text h5 size="rg">
        SourceSansPro
      </Text>
      <Spacer size={1} />
      {Object.keys(fontWeights).map((weight: any) => (
        <Fragment key={weight}>
          <div>
            {Object.keys(fontSizes).map((size: any) => (
              <Text p key={`${size}-${weight}`} size={size} weight={weight}>
                {fontWeights[weight]}
              </Text>
            ))}
          </div>
          <Spacer size={1} />
        </Fragment>
      ))}
      <Spacer size={1} />
    </div>
  );

  const renderIconography = (): JSX.Element => (
    <div id="iconography">
      <Text h3 size="bg">
        Iconography
      </Text>
      <Spacer size={1} />
      <Flex
        flexWrap
        flexDirection="row"
        alignItems="center"
        justifyContent="between"
      >
        {icons.map((icon) => (
          <Icon key={icon} name={icon} size={70} />
        ))}
      </Flex>
      <Spacer size={2} />
    </div>
  );

  const renderButtons = (): JSX.Element => (
    <div id="buttons">
      <Text h3 size="bg">
        Buttons
      </Text>
      <Spacer size={1} />
      <Flex
        flexWrap
        flexDirection="row"
        alignItems="center"
        justifyContent="between"
      >
        <Button primary>Primary Button</Button>
        <Button primary loading>
          Loading Primary Button
        </Button>
        <Button primary disabled>
          Primary Disabled Button
        </Button>
        <Button primary loading disabled>
          Loading Primary Disabled Button
        </Button>
      </Flex>
      <Spacer size={1} />
      <Flex
        flexWrap
        flexDirection="row"
        alignItems="center"
        justifyContent="between"
      >
        <Button small primary>
          Small Primary Button
        </Button>
        <Button small primary loading>
          Loading Small Primary Button
        </Button>
        <Button small primary disabled>
          Small Primary Disabled Button
        </Button>
        <Button small primary loading disabled>
          Loading Small Primary Disabled Button
        </Button>
      </Flex>
      <Spacer size={1} />
      <Flex
        flexWrap
        flexDirection="row"
        alignItems="center"
        justifyContent="between"
      >
        <Button secondary>Secondary Button</Button>
        <Button loading secondary>
          Loading Secondary Button
        </Button>
        <Button secondary disabled>
          Secondary Disabled Button
        </Button>
        <Button loading secondary disabled>
          Loading Secondary Disabled Button
        </Button>
      </Flex>
      <Spacer size={1} />
      <Flex
        flexWrap
        flexDirection="row"
        alignItems="center"
        justifyContent="between"
      >
        <Button small secondary>
          Small Secondary Button
        </Button>
        <Button small loading secondary>
          Loading Small Secondary Button
        </Button>
        <Button small secondary disabled>
          Small Secondary Disabled Button
        </Button>
        <Button small loading secondary disabled>
          Loading Small Secondary Disabled Button
        </Button>
      </Flex>
      <Spacer size={2} />
    </div>
  );

  const renderInputs = (): JSX.Element => (
    <div id="inputs">
      <Text h3 size="bg">
        Inputs
      </Text>
      <Spacer size={1} />
      <Flex flexWrap flexDirection="row" justifyContent="between">
        <div className={styles.halfWidth}>
          <TextInput defaultValue="Value" />
          <Spacer size={1} />
          <TextInput label="Label" placeholder="Labeled input" />
          <Spacer size={1} />
          <TextInput
            label="Label"
            message="Helper Message"
            placeholder="Labeled input with helper message"
          />
          <Spacer size={1} />
          <TextInput
            error
            label="Label"
            defaultValue="Value"
            message="Error Message"
          />
          <Spacer size={1} />
          <TextInput
            disabled
            label="Label"
            message="Helper Message"
            placeholder="Disabled labeled input with helper message"
          />
        </div>
        <div className={styles.halfWidth}>
          <TextInput placeholder="Placeholder" />
          <Spacer size={1} />
          <TextInput
            required
            label="Label"
            placeholder="Required labeled input"
          />
          <Spacer size={1} />
          <TextInput
            required
            label="Label"
            message="Helper Message"
            placeholder="Required labeled input with helper message"
          />
          <Spacer size={1} />
          <TextInput
            success
            label="Label"
            defaultValue="Value"
            message="Success Message"
          />
          <Spacer size={1} />
          <TextInput
            label="Label"
            defaultValue="Value"
            message="Helper Message"
            onClickArrowButton={(): void => console.log("clicke input button")}
          />
        </div>
      </Flex>
      <Spacer size={2} />
    </div>
  );

  return (
    <Layout>
      <Container>
        <Spacer size={2} />
        <Text h1 size="xxl">
          UI Kit
        </Text>
        <Spacer size={3} />
        {renderTypography()}
        {renderIconography()}
        {renderButtons()}
        {renderInputs()}
        <CookieBanner
          isOpen={showCookieBanner}
          onClose={(): void => setShowCookieBanner(false)}
          onAccept={(): void => setShowCookieBanner(false)}
          message="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis eu rhoncus, nascetur sem tristique."
        />
      </Container>
    </Layout>
  );
};

export default UIKitPage;
